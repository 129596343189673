/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import Header from "../Header"
import Sidebar from "../Sidebar"
import { Link, useNavigate, useParams } from "react-router-dom"
import { favicon, imagesend } from "../imagepath"
import FeatherIcon from "feather-icons-react/build/FeatherIcon"
import { DatePicker } from "antd"
import { useMutation, useQuery } from "@tanstack/react-query"
import { doctorKeys } from "../../features/doctors/query-keys"
import { DoctorApi } from "../../features/doctors/api"
import { Form, FormikProvider, useFormik } from "formik"
import moment from "moment"
import { use } from "i18next"

const EditDoctor = () => {
  const { id } = useParams()
  const { data, refetch } = useQuery({
    queryKey: doctorKeys.getById(id),
    queryFn: () => DoctorApi.getDoctorById(id),
    enabled: !!id,
  })

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id, refetch])

  const mutation = useMutation({
    mutationFn: (updatedData) => DoctorApi.updateDoctor(id, updatedData),
  })
const navigate = useNavigate()
  const handleSubmit = (values) => {
    mutation.mutate(values, {
      onSuccess: () => {
        refetch()
        navigate("/doctorlist")
      },
    })
  }

  const doctorFormData = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    password: "",
    password_confirmation: "",
    date_of_birth: data?.date_of_birth || "",
    specialization: data?.details?.specialization || "",
    registration_number: data?.details?.registration_number || "",
    address: data?.address || "",
    phone_number: data?.phone_number || "",
  }

  const formik = useFormik({
    initialValues: doctorFormData,
    enableReinitialize: true, // Reinitialize form values when data is fetched
    onSubmit: handleSubmit,
  })

  const { setFieldValue, values, handleChange } = formik
  console.log(values)
  console.log(formik.errors)
  const onChange = (date, dateString) => {
    setFieldValue("date_of_birth", dateString)
  }

  const loadFile = (event) => {
    // Handle file loading logic here
  }

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item1"
        id1="menu-items1"
        activeClassName="edit-doctor"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Doctors </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Doctor</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <FormikProvider value={formik}>
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Doctor Details</h4>
                            </div>
                          </div>

                          {/* First Name */}
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                First Name{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Last Name */}
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                Last Name{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Email */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Email <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Date of Birth */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms cal-icon">
                              <label>
                                Date Of Birth{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <DatePicker
                                className="form-control datetimepicker"
                                onChange={onChange}
                                value={
                                  values.date_of_birth
                                    ? moment(values.date_of_birth)
                                    : null
                                }
                              />
                            </div>
                          </div>

                          {/* Password */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Password <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Confirm Password */}
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Confirm Password{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="password"
                                name="password_confirmation"
                                value={values.password_confirmation}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Specialization */}
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                Specialization{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="specialization"
                                value={values.specialization}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Registration Number */}
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                Registration number{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="registration_number"
                                value={values.registration_number}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          {/* Address */}
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                Address <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group local-forms">
                              <label>
                                Phone Number <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="phone_number"
                                value={values.phone_number}
                                onChange={handleChange}
                              />
                            </div>
                          </div>


                          {/* Avatar */}
                          {/* <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-top-form">
                              <label className="local-top">
                                Avatar <span className="login-danger">*</span>
                              </label>
                              <div className="settings-btn upload-files-avatar">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file"
                                  onChange={loadFile}
                                  className="hide-input"
                                />
                                <label htmlFor="file" className="upload">
                                  Choose File
                                </label>
                              </div>
                            </div>
                          </div> */}

                          {/* Submit Button */}
                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button
                                type="submit"
                                className="btn btn-primary submit-form me-2"
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary cancel-form"
                                onClick={() => {
                                  window.history.back()
                                }
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notification-box">
            <div className="msg-sidebar notifications msg-noti">
              <div className="topnav-dropdown-header">
                <span>Messages</span>
              </div>
              <div className="drop-scroll msg-list-scroll" id="msg_list">
                <ul className="list-box">
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Richard Miles </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item new-message">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">John Doe</span>
                          <span className="message-time">1 Aug</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Tarah Shropshire{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Mike Litorus</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Catherine Manseau{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">D</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Domenic Houston{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">B</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Buster Wigton{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">R</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            {" "}
                            Rolland Webber{" "}
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">C</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author"> Claire Mapes </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">M</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Melita Faucher</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">J</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Jeffery Lalor</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">L</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">Loren Gatlin</span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="chat.html">
                      <div className="list-item">
                        <div className="list-left">
                          <span className="avatar">T</span>
                        </div>
                        <div className="list-body">
                          <span className="message-author">
                            Tarah Shropshire
                          </span>
                          <span className="message-time">12:28 AM</span>
                          <div className="clearfix" />
                          <span className="message-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                          </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to="chat.html">See all messages</Link>
              </div>
            </div>
          </div>
        </div>
        <div
          id="delete_patient"
          className="modal fade delete-modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <img src={imagesend} alt="" width={50} height={46} />
                <h3>Are you sure want to delete this ?</h3>
                <div className="m-t-20">
                  {" "}
                  <Link
                    to="#"
                    className="btn btn-white me-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </Link>
                  <button type="submit" className="btn btn-danger">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default EditDoctor
