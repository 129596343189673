import React, { useState } from "react"
import { useFormik } from "formik"
import { DatePicker } from "antd"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { PatientApi } from "../api"

// const validationSchema = Yup.object({
//   first_name: Yup.string().required("First Name is required"),
//   last_name: Yup.string().required("Last Name is required"),
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   password: Yup.string().required("Password is required"),
//   password_confirmation: Yup.string()
//     .oneOf([Yup.ref("password"), null], "Passwords must match")
//     .required("Password confirmation is required"),
//   date_of_birth: Yup.string().required("Date of Birth is required"),
//   glycaemia_unit: Yup.string().required("glycaemia_unit is required"),
//   ratio_unit: Yup.string().required("Registration number is required"),
//   address: Yup.string().nullable(),
//   city: Yup.string().nullable(),
// })

export const AddPatientForm = () => {
  const { t } = useTranslation()
  const [isClicked, setIsClicked] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone_number: "",
      password_confirmation: "",
      date_of_birth: "",
      glycaemia_unit: "",
      ratio_unit: "",
      morning_ratio: "",
      midday_ratio: "",
      evening_ratio: "",
      insulin_sensitivity: "",
      target: "",
      injection_calculation: "0",
      monitoring_book: "0",
    },
    // validationSchema, // Yup validation schema
    onSubmit: async (values) => {
      try {
        await PatientApi.createPatient(values) // Submit form data to API

        navigate("/patientslist")
      } catch (error) {
        console.error("Error adding patient:", error)
        alert("Failed to add patient")
      }
    },
  })

  const onChangeDate = (date, dateString) => {
    setIsClicked(true)
    formik.setFieldValue("date_of_birth", dateString) // Set date in Formik
  }

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-heading">
                <h4>{t("Add patient")}</h4>
              </div>
            </div>

            {/* First Name */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  First Name <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="error">{formik.errors.first_name}</div>
                ) : null}
              </div>
            </div>

            {/* Last Name */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Last Name <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="error">{formik.errors.last_name}</div>
                ) : null}
              </div>
            </div>

            {/* Email */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Email <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            {/* Password */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Password <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
            </div>

            {/* Phone Number */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Phone Number <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="phone_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_number}
                />
                {formik.touched.phone_number && formik.errors.phone_number ? (
                  <div className="error">{formik.errors.phone_number}</div>
                ) : null}
              </div>
            </div>
            {/* Confirm Password */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Confirm Password <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="password_confirmation"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password_confirmation}
                />
                {formik.touched.password_confirmation &&
                formik.errors.password_confirmation ? (
                  <div className="error">
                    {formik.errors.password_confirmation}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Date of Birth */}
            <div className="col-12">
              <div className="form-group local-forms cal-icon">
                <label>
                  Date Of Birth <span className="login-danger">*</span>
                </label>
                <DatePicker
                  className="form-control datetimepicker"
                  onChange={onChangeDate}
                  suffixIcon={null}
                  style={{
                    borderColor: isClicked
                      ? "#2E37A4"
                      : "rgba(46, 55, 164, 0.1)",
                  }}
                />
                {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                  <div className="error">{formik.errors.date_of_birth}</div>
                ) : null}
              </div>
            </div>

            {/* glycaemia_unit */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Glycaemia unit <span className="login-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="glycaemia_unit"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.glycaemia_unit}
                >
                  <option value="">Select unit</option>

                  <option value="g/l">g/l</option>

                  <option value="mg/dl">mg/dl</option>
                  <option value="mmol/l">mmol/l</option>
                </select>
                {formik.touched.glycaemia_unit &&
                formik.errors.glycaemia_unit ? (
                  <div className="error">{formik.errors.glycaemia_unit}</div>
                ) : null}
              </div>
            </div>

            {/* Registration Number */}
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Ratio unit <span className="login-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="ratio_unit"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ratio_unit}
                >
                  <option value="">Select unit</option>

                  <option value="U/10g">U/10g</option>
                  <option value="g/U">g/U</option>
                </select>
                {formik.touched.ratio_unit && formik.errors.ratio_unit ? (
                  <div className="error">{formik.errors.ratio_unit}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Morning Ratio <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="morning_ratio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.morning_ratio}
                />
                {formik.touched.morning_ratio && formik.errors.morning_ratio ? (
                  <div className="error">{formik.errors.morning_ratio}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Midday Ratio <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="midday_ratio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.midday_ratio}
                />
                {formik.touched.midday_ratio && formik.errors.midday_ratio ? (
                  <div className="error">{formik.errors.midday_ratio}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Evening Ratio <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="evening_ratio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.evening_ratio}
                />
                {formik.touched.evening_ratio && formik.errors.evening_ratio ? (
                  <div className="error">{formik.errors.evening_ratio}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Insulin Sensitivity <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="insulin_sensitivity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.insulin_sensitivity}
                />
                {formik.touched.insulin_sensitivity &&
                formik.errors.insulin_sensitivity ? (
                  <div className="error">
                    {formik.errors.insulin_sensitivity}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group local-forms">
                <label>
                  Target <span className="login-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="target"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.target}
                />
                {formik.touched.target && formik.errors.target ? (
                  <div className="error">{formik.errors.target}</div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group ">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  name="injection_calculation"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "injection_calculation",
                      e.target.checked ? 1 : 0
                    )
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.injection_calculation === 1}
                />
                <label>
                  Injection Calculation 
                </label>
                {formik.touched.injection_calculation &&
                formik.errors.injection_calculation ? (
                  <div className="error">
                    {formik.errors.injection_calculation}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group ">
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  name="monitoring_book"
                  onChange={(e) =>
                    formik.setFieldValue(
                      "monitoring_book",
                      e.target.checked ? 1 : 0
                    )
                  }
                  onBlur={formik.handleBlur}
                  checked={formik.values.monitoring_book === 1}
                />
                <label>
                  Monitoring Book
                </label>
                {formik.touched.monitoring_book &&
                formik.errors.monitoring_book ? (
                  <div className="error">
                    {formik.errors.monitoring_book}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Address */}
            {/* <div className="col-12">
            <div className="form-group local-forms">
              <label>Address</label>
              <textarea
                className="form-control"
                name="address"
                rows={3}
                cols={30}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
            </div>
          </div> */}

            {/* Submit Button */}
            <div className="col-12">
              <div className="patient-submit text-end">
                <button
                  type="submit"
                  className="btn btn-primary submit-form me-2"
                >
                  Submit
                </button>
                <button type="reset" className="btn btn-secondary cancel-form">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
